import { Box, Button } from "@mui/material";
import { useRouter } from 'next/router';

export default function Custom404() {
    const router = useRouter();
    return (
        <Box sx={{ width: "100%", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", p: 1 }}>
            <h1>404 - Page Not Found</h1>
            <Button variant="outlined" sx={{ mt: 1 }} onClick={() => router.push('/')}>Go To Home Page</Button>
        </Box>
    )
}